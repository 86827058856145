const currentTabReducer = (
  state = {
    currentTab: "",
  },
  action
) => {
  switch (action.type) {
    case "CURRENT_TAB":
      return action.payload;

    default:
      return state;
  }
};

export default currentTabReducer;
