const cifIdReducer = (
  state = {
    Id: "",
  },
  action
) => {
  switch (action.type) {
    case "UPDATE_CIF_ID":
      return action.payload;

    default:
      return state;
  }
};

export default cifIdReducer;
