const userDetailsReducer = (
  state = {
    Id: "",
    username: "",
    fullname: "",
    tokenid: "",
    FinalizedResult:false,
    GenerateCdrs:false,
    SetAsReported:false,
    DeleteCif:false
  },
  action
) => {
  switch (action.type) {
    case "UPDATE_USER_DETAILS":
      return action.payload;

    default:
      return state;
  }
};

export default userDetailsReducer;
