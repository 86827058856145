export const updateUser = (userDetails) => {
  return {
    type: "UPDATE_USER_DETAILS",
    payload: userDetails,
  };
};
export const savedCif = (x) => {
  return {
    type: "SAVED_CIF",
    payload: x,
  };
};
export const updateCifId = (Id) => {
  return {
    type: "UPDATE_CIF_ID",
    payload: Id,
  };
};
export const currentTab = (x) => {
  return {
    type: "CURRENT_TAB",
    payload: x,
  };
};
