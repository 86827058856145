import userDetailsReducer from "./UserDetails";
import cifIdReducer from "./CifId";
import saveCifReducer from "./SaveCif";
import currentTabReducer from "./CurrentTab";
import { combineReducers } from "redux";
const allReducers = combineReducers({
  userDetails: userDetailsReducer,
  cifId: cifIdReducer,
  cifDetails: saveCifReducer,
  currentTab: currentTabReducer,
});

export default allReducers;
