/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import appSettings from "../assets/appSettings";
import axios from "axios";
import { DatePicker, Space, Button, Checkbox } from "antd";
import moment from "moment";
const tokenid = localStorage.getItem("tokenid");
axios.defaults.headers.common["Authorization"] = "Bearer " + tokenid;
const Home = () => {
  const [datefrom, setDateFrom] = useState(moment(new Date(), "MM/dd/yyyy"));
  const [dateto, setDateTo] = useState(moment(new Date(), "MM/dd/yyyy"));
  const [all, setAll] = useState(true);
  const [newBlob, setNewBlob] = useState(null);

  const handleDashBoard = async () => {
    try {
      await axios({
        url: appSettings.apiUrl + "downloadreport", //your url
        method: "GET",
        responseType: "blob", // important
        params: {
          reportName: "dashboard.rpt",
          param1: localStorage.getItem("Id"),
          isExcel: false,
          param2: moment(datefrom).format("YYYYMMDD"),
          param3: moment(dateto).format("YYYYMMDD"),
          param4: all,
        },
      }).then((response) => {
        let newBlob;
        newBlob = new Blob([response.data], {
          type: "application/pdf",
        });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        const data = window.URL.createObjectURL(newBlob);
        setNewBlob(data);
      });
    } catch (error) {}
  };
  useEffect(() => {
    handleDashBoard();
  }, []);
  return (
    <div>
      <Space>
        Date From
        <DatePicker
          placeholder="Date From"
          style={{ width: "150px" }}
          value={datefrom}
          format={"MM/DD/yyyy"}
          onChange={(value, dateString) => {
            setDateFrom(value);
          }}
        />
        Date To
        <DatePicker
          placeholder="Date To"
          style={{ width: "120px" }}
          value={dateto}
          format={"MM/DD/yyyy"}
          onChange={(value, dateString) => {
            setDateTo(value);
          }}
        />
        <Checkbox
          checked={all}
          onChange={(e) => {
            setAll(e.target.checked);
          }}
        >
          All
        </Checkbox>
        <Button type="primary" onClick={handleDashBoard}>
          Refresh
        </Button>
      </Space>
      <div className="mt-2">
        <iframe
          title="xxx"
          src={newBlob}
          style={{ width: "100%", height: "700px" }}
        ></iframe>
      </div>
    </div>
  );
};

export default Home;
