import axios from "axios";
import appSettings from "../assets/appSettings";

export async function postData(endPoint, values) {
  const tokenid = localStorage.getItem("tokenid");
  axios.defaults.headers.common["Authorization"] = "Bearer " + tokenid;
  try {
    let response = await axios.post(appSettings.apiUrl + endPoint, values);

    return response.data;
  } catch (error) {
    alert(error);
    return error;
  }
}

export async function getListOfRecords(endPoint, params) {
  const tokenid = localStorage.getItem("tokenid");
  axios.defaults.headers.common["Authorization"] = "Bearer " + tokenid;
  try {
    let response = await axios.get(appSettings.apiUrl + endPoint, params);

    return response.data;
  } catch (error) {
    return error.message;
  }
}
