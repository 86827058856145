const saveCifReducer = (
  state = {
    PatientInformation: false,
    ConsultationAndAdmission: false,
    DispositionAtTimeOfReport: false,
    HealthStatus: false,
    SpecialPopulation: false,
    ClinicalInformation: false,
    ContactTracing: false,
    LaboratoryInformation: false,
  },
  action
) => {
  switch (action.type) {
    case "SAVED_CIF":
      return action.payload;

    default:
      return state;
  }
};

export default saveCifReducer;
