import React, { useEffect, useState } from "react";
import appSettings from "../assets/appSettings";
import axios from "axios";
import { message, Button, Row, Col } from "antd";
import logo from "../assets/hospitallogo.png";
export default function DownloadCif(props) {
  const cifno = props.match.params.cifno;
  const [name, setName] = useState("");
  const handleGetPatientProfile = async () => {
    try {
      let res = await axios.get(appSettings.apiUrl + "getListOfRecordsx", {
        params: {
          param1: "getPatientProfileByCifNo",
          param2: cifno,
        },
      });

      if (res) {
        console.log(res);
        setName(
          res.data[0].lastname +
            ", " +
            res.data[0].firstname +
            " " +
            res.data[0].middlename
        );
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    handleGetPatientProfile();
  });
  const handlePrint = async () => {
    try {
      await axios({
        url: appSettings.apiUrl + "downloadreport", //your url
        method: "GET",
        responseType: "blob", // important
        params: {
          reportName: "cifx.rpt",
          param1: cifno,
          isExcel: false,
        },
      }).then((response) => {
        let newBlob;
        newBlob = new Blob([response.data], {
          type: "application/pdf",
        });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        const data = window.URL.createObjectURL(newBlob);

        window.open(data, "_blank");
        setTimeout(function () {
          window.URL.revokeObjectURL(data);
        }, 100);
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={8}></Col>
        <Col xs={24} sm={24} md={8}>
          <div>
            <img
              src={logo}
              alt="Logo"
              style={{ width: "100%", height: "200px" }}
            />
          </div>
          <div className="text-center font-bold text-lg">
            JT Cenica Molecular Laboratory
          </div>
          <div className="font-medium text-center">
            1 Sta. Escolastica St. Corner Roxas Blvd. Service Road Pasay City
          </div>
          <div className="text-md font-bold mb-2">Patient Name:{name}</div>
          <div>
            <Button
              style={{ width: "100%" }}
              type="primary"
              onClick={handlePrint}
            >
              Download/Print CIF
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}
